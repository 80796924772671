import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UAParser from 'ua-parser-js';
import $ from "jquery";
import { API_BASE_URL } from './config';
import Cookies from 'js-cookie';

const RouteLogger = () => {
  const location = useLocation();
  const [lastRoute, setLastRoute] = useState('');
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  const [ipAddress, setIpAddress] = useState('');
  const [deviceDetails, setDeviceDetails] = useState({});

  // Track route changes
  useEffect(() => {
    setLastRoute(currentRoute);
    setCurrentRoute(location.pathname);
  }, [location]);

  // Fetch IP Address and Device Info
  useEffect(() => {
    // Get IP Address
    fetch('https://api.ipify.org?format=json')
      .then((res) => res.json())
      .then((data) => setIpAddress(data.ip))
      .catch((err) => console.error('Error fetching IP:', err));

    // Get Device Info
    const parser = new UAParser();
    setDeviceDetails(parser.getResult());
  }, []);

  // Log data to the backend
  useEffect(() => {
    if (lastRoute) {
      const logData = JSON.stringify({
        "registrationid":Cookies.get("registrationid"),
        "lastpage":lastRoute,
        "pagename":currentRoute,
        "IPAddress":ipAddress,
        "machinename":deviceDetails.ua,
        "accessby":localStorage.getItem("email"),
      });
      $.ajax
      ({
          method: "post",
          url: `${API_BASE_URL}/userRegistration/SaveCustomerPageAudits`,
          data: logData,
          headers: {
             // "Authorization":$.cookie('access_token'),
              'Content-Type': 'application/json; charset=utf-8'       
            },
          success: function (data) {
              //alert("saves");
          },
          error: function (xhr, textStatus, error) {
  
          }
      });
      
    }
  }, [currentRoute, lastRoute, ipAddress, deviceDetails]);

  return null; // No UI component needed; it's for logging
};

export default RouteLogger;

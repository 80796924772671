import React, {useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Cookies from 'js-cookie';
import swal from "sweetalert2";
import { API_BASE_URL, MEDIA_BASE_URL, BASE_URL } from "../../config/config";
import { encrypt, decrypt } from '../../config/encrypt-decrypt';
import axios from 'axios';

function PaymentResponse () {

//////////////////////////////////////////////////////Get Company Details //////////////////////
const [charges, SetCharges]=useState({
	bankcharge:0,
	postalcharge:0,	
	freecharge:0
});
const [companydetails, setCompanyDetails] = useState([]);
async function fetchcompanydetails(regno) {
	debugger;
	try {
	  const response = await fetch(`${API_BASE_URL}/api/getCompanyPayment?registrationid=${regno}`, {
		method: 'GET',
		headers: {		 
		  'Content-Type': 'application/json; charset=utf-8'
		},		
	  });	  
	  const data = await response.json();
	  debugger;
	  setCompanyDetails(data.d); 
	  
	  SetCharges({
		bankcharge:data.d.bankcharge,
		postalcharge:data.d.postalcharge,	
		freecharge:data.d.freecharge
	}); 

	} catch (error) {
	  console.log("Test Error");
	}
  }
    //////////////////////////////////////////////////////Get Invoice Details //////////////////////
	const [languagename, setLanguageName] = useState("English");
const [invoicedetails, setInvoiceDetails] = useState([]);
const [invoiceproduct, setInvoiceProduct] = useState([]);
async function fetchinvoicedetails(invoiceno,regno) {
	debugger;
	try {
	  const response = await fetch(`${API_BASE_URL}/invoice/GetInvoiceDetailsPayment?registrationid=${regno}&invoiceno=${invoiceno}`, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json; charset=utf-8'
		},
	  });	  
	  const data = await response.json();
	  debugger;
	  setInvoiceDetails(data.d);
	  setInvoiceProduct(data.prod);   
	  let invlanguage=data.d.invoicinglanguage;
	  setLanguageName(invlanguage);
	} catch (error) {
	  console.log("Test Error");
	}
}

function updateinvoicepaymentdetails(){
	let search = window.location.search;
    let params = new URLSearchParams(search);
	let bankchares = parseFloat(params.get('amount')) * charges.bankcharge / 100;  

	let datas = JSON.stringify({                  
		"registrationid": params.get('regno'), 
		"invoiceno":params.get('invoiceno'),
		"paidamount":params.get('amount'),
		"onlinepaid":params.get('amount'),
		"bnkcharge":bankchares,
		"freecharge":charges.freecharge,
		"cmpnycharge":0,
		"paidstatus":"Paid",
		"paidby":"Online",
		"tempid":params.get('tempid'),
	});


	let config = {
		method: 'post',
		maxBodyLength: Infinity,
		headers: {		 
			'Content-Type': 'application/json; charset=utf-8'
		  },	
		mimeType: "multipart/form-data",
		body : datas
	  };	  
	  fetch(`${API_BASE_URL}/invoice/updateInvoicePaymentonline`, config)
	  .then((response) => {	  
		if (!response.ok) {
		 // throw new Error('Network response was not ok');
		}
		return response.json(); // Extract the response body as JSON	  
	   // console.log(response.ok);
	  })
	  .then(data => {      
		debugger;     
		
	  
	  })   
	  .catch((error) => {
		console.log(error);
	  });

}


    const [invoiceno, setInvoiceNo] = useState("");
    const [dueamount, setDueAmount] = useState("");
    useEffect( async () => {
      let search = window.location.search;
    let params = new URLSearchParams(search);
      let invoiceno = params.get('invoiceno');
      let regno = params.get('regno');
      let amount = params.get("amount");
	  let tempid = params.get("tempid");
      let customerid=decrypt(params.get("cstmrid"));
      let referenceno=decrypt(params.get("rfncno"));
	debugger;
	let invoicenodec=await getdecryptcode(invoiceno);
	let regnodec=await getdecryptcode(regno);
      setInvoiceNo(invoicenodec);
      setDueAmount(amount);

      await fetchcompanydetails(regno);
      await fetchinvoicedetails(invoiceno, regno);
	  updateinvoicepaymentdetails(); 
	  
	let invlanguage=languagename;
	const timer = setTimeout(() => {
		debugger;
		window.location.href = `https://zwato.com/re_invoice/?tempid=${tempid}&invoiceno=${invoicenodec}&regno=${regnodec}&language=${languagename}`;  // Redirect after 5 seconds
	  }, 5000);

    },[]);

	async function getdecryptcode (text){   
		let datas = JSON.stringify({                  
			"text": text       
		});
		let config = {
		  method: 'post',
		  maxBodyLength: Infinity,
		  headers: { 
			'Content-Type': 'application/json'
		  },
		  body : datas
		};
	   let response= await axios.post(`${API_BASE_URL}/userRegistration/generatedecrypt`, datas,{
		headers: { 
		  'Content-Type': 'application/json'
		},
	   });
	  // console.log(response.data['d']);
	   return response.data['d'];
		
	}	  
	async function getencryptcode (text){   
	  let datas = JSON.stringify({                  
		  "text": text       
	  });
	  let config = {
		method: 'post',
		maxBodyLength: Infinity,
		headers: { 
		  'Content-Type': 'application/json'
		},
		body : datas
	  };
	 let response= await axios.post(`${API_BASE_URL}/userRegistration/generateencrypt`, datas,{
	  headers: { 
		'Content-Type': 'application/json'
	  },
	 });
	// console.log(response.data['d']);
	 return response.data['d'];	
	 
	}



  
      return (
  <>

<div className="login-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10">
                            <div className="row login-box p-3">
                                <div className="col-lg-3 col-md-3">
                                    <img src={companydetails && (companydetails.photo)} className="img-fluid"/>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                    <h3 class="">Thank You !</h3>
                                    <p className="text-dark">Your payment for Invoice No. #{invoiceno} has been successfully processed. The outstanding amount of {dueamount} has been settled. We appreciate your timely payment.</p>
                                    <br/>
                                    <button type="submit" class="btn btn-primary btn-custom">View Receipt</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


    {/* <section className="cont-area h-100">
	<div className="container h-100">
		<div className="row h-100 justify-content-center align-items-center">
			<div className="col-md-6 text-center">
				<div className="login-sec1 animate__animated animate__zoomInDown">
					<h2><i className="mdi mdi-check-circle-outline text-success"></i></h2>
					<h4><strong>Thank You !</strong></h4>
					<p className="mb-3">Your Payment for Invoice No. <strong>#{invoiceno}</strong> and  <br/>Due Amount <strong>{dueamount}</strong> is Successful.</p>	
					
					<div className="row mb-3 justify-content-center">
						<div className="col-12 text-center">

							<button type="button" className="btn btn-primary btn-sm">View Receipt</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> */}
  </>
      );
    };
    export default PaymentResponse;

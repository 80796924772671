import { lazy } from 'react';
const routes = [

      {
        path: '',
        component: lazy(() => import('../components/section-component/Dashboard')),
        exact: true
      },
      {
        path: 'dashboard',
        component: lazy(() => import('../components/section-component/Dashboard')),
        exact: true
      },
      {
        path: 'Viewcustomer',
        component: lazy(() => import('../components/section-component/Viewcustomer')),
        exact: false
      },
      {
        path: 'Addnewcustomer',
        component: lazy(() => import('../components/section-component/Addnewcustomer')),
        exact: true
      },
      {
        path: 'ImportCustomer',
        component: lazy(() => import('../components/section-component/ImportCustomer')),
        exact: true
      },
      {
        path: 'Invoices',
        component: lazy(() => import('../components/section-component/Invoice')),
        exact: true
      },
      {
        path: 'CreateInvoice',
        component: lazy(() => import('../components/section-component/CreateInvoice')),
        exact: true
      },
      {
        path: 'EditInvoice',
        component: lazy(() => import('../components/section-component/EditInvoice')),
        exact: true
      },
      {
        path: 'ImportInvoice',
        component: lazy(() => import('../components/section-component/ImportInvoice')),
        exact: true
      },
      {
        path: 'OpenInvoice',
        component: lazy(() => import('../components/section-component/OpenInvoice')),
        exact: true
      },
      {
        path: 'OpenInvoicetoInvoice',
        component: lazy(() => import('../components/section-component/OpenInvoicetoInvoice')),
        exact: true
      },
      {
        path: 'InvoicePrev',
        component: lazy(() => import('../components/section-component/InvoicePrev')),
        exact: true
      },
      
      {
        path: 'Estimate',
        component: lazy(() => import('../components/section-component/Estimate')),
        exact: true
      },

      {
        path: 'CreateEstimate',
        component: lazy(() => import('../components/section-component/CreateEstimate')),
        exact: true
      },
      {
        path: 'EditEstimate',
        component: lazy(() => import('../components/section-component/EditEstimate')),
        exact: true
      },
      {
        path: 'ImportEstimate',
        component: lazy(() => import('../components/section-component/ImportEstimate')),
        exact: true
      },
      {
        path: 'BankDetails',
        component: lazy(() => import('../components/section-component/BankDetails')),
        exact: true
      }, 
      {
        path: 'MyAccount',
        component: lazy(() => import('../components/section-component/MyAccount')),
        exact: true
      },
      {
        path: 'AdminProfile',
        component: lazy(() => import('../components/section-component/AdminProfile')),
        exact: true
      },
      {
        path: 'TradeName',
        component: lazy(() => import('../components/section-component/TradeName')),
        exact: true
      },
      {
        path: 'TradeNameList',
        component: lazy(() => import('../components/section-component/TradeNameList')),
        exact: true
      },
      
      {
        path: 'MyWallet',
        component: lazy(() => import('../components/section-component/MyWallet')),
        exact: true
      },
      {
        path: 'NewProduct',
        component: lazy(() => import('../components/section-component/NewProduct')),
        exact: true
      },
      {
        path: 'ImportProduct',
        component: lazy(() => import('../components/section-component/ImportProduct')),
        exact: true
      },


      {
        path: 'MyCalendar',
        component: lazy(() => import('../components/section-component/MyCalendar')),
        exact: true
      },
      {
        path: 'MyCalendarList',
        component: lazy(() => import('../components/section-component/MyCalendarList')),
        exact: true
      },
      
      {
        path: 'AddEmployee',
        component: lazy(() => import('../components/section-component/AddEmployee')),
        exact: true
      },
      {
        path: 'ViewEmploye',
        component: lazy(() => import('../components/section-component/ViewEmploye')),
        exact: true
      },
      {
        path: 'EditEmployee',
        component: lazy(() => import('../components/section-component/EditEmployee')),
        exact: true
      },

      {
        path: 'AddPayroll',
        component: lazy(() => import('../components/section-component/AddPayroll')),
        exact: true
      },
      {
        path: 'Payrolllist',
        component: lazy(() => import('../components/section-component/Payrolllist')),
        exact: true
      },      
      {
        path: 'Price',
        component: lazy(() => import('../components/section-component/Price')),
        exact: true
      },
      {
        path: 'Reports',
        component: lazy(() => import('../components/section-component/Reports')),
        exact: true
      },
      {
        path: 'ReportPdf',
        component: lazy(() => import('../components/section-component/ReportPdf')),
        exact: true
      },
      {
        path: 'ReportPreview',
        component: lazy(() => import('../components/section-component/ReportPreview')),
        exact: true
      },      
      {
        path: 'OpenTicket',
        component: lazy(() => import('../components/section-component/OpenTicket')),
        exact: true
      },
      {
        path: 'ViewTicket',
        component: lazy(() => import('../components/section-component/ViewTicket')),
        exact: true
      },
      {
        path: 'FormExample',
        component: lazy(() => import('../components/section-component/FormExample')),
        exact: true
      },
      {
        path: 'ReminderTemplates',
        component: lazy(() => import('../components/section-component/ReminderTemplates')),
        exact: true
      },
      {
        path: 'Deleteditems',
        component: lazy(() => import('../components/section-component/Deleteditems')),
        exact: true
      },
      {
        path: 'InvoiceTemplateSetting',
        component: lazy(() => import('../components/global-component/InvoiceTemplateSetting')),
        exact: true
      },
      {
        path: 'EstTempSetting',
        component: lazy(() => import('../components/global-component/EstTempSetting')),
        exact: true
      },
      {
        path: 'AutoEmailSetting',
        component: lazy(() => import('../components/global-component/AutoEmailSetting')),
        exact: true
      },
      {
        path: 'PayrollSetting',
        component: lazy(() => import('../components/global-component/PayrollSetting')),
        exact: true
      },      
      {
        path: 'UserSetting',
        component: lazy(() => import('../components/section-component/UserSetting')),
        exact: true
      },
      {
        path: 'LetterHead',
        component: lazy(() => import('../components/section-component/LetterHead')),
        exact: true
      },
      {
        path: 'Offerletterlist',
        component: lazy(() => import('../components/section-component/Offerletterlist')),
        exact: true
      },
      {
        path: 'JoiningLetterList',
        component: lazy(() => import('../components/section-component/JoiningLetterList')),
        exact: true
      },
      {
        path: 'Offerletter',
        component: lazy(() => import('../components/section-component/Offerletter')),
        exact: true
      },
      {
        path: 'Editofferletter',
        component: lazy(() => import('../components/section-component/Editofferletter')),
        exact: true
      },
      {
        path: 'Joiningletter',
        component: lazy(() => import('../components/section-component/Joiningletter')),
        exact: true
      },
      {
        path: 'EditJoiningLetter',
        component: lazy(() => import('../components/section-component/EditJoiningLetter')),
        exact: true
      },
      {
        path: 'SignOut',
        component: lazy(() => import('../components/section-component/SignOut')),
        exact: true
      }
];
export default routes;